.logo {
  max-width: 120px;
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-links {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.nav-links a {
  margin-left: 15px;
  color: #333;
  text-decoration: none;
}

#header1 {
  background-color: transparent;
}

#header2 {
  border: 1px solid lightslategray;
  padding: 12px 24px;
  border-radius: 50px;
}
#header3{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
background-color: transparent;
  border: 1px solid #146ef5;
  color: #146ef5;
  font-family: "Fira Sans", sans-serif;
  font-weight: 500;
  border-radius: 40px;
}
