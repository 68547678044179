#home1 {
  background: linear-gradient(45deg, #ffffff, #d1f1ff);
  background-size: 200% 200%;
  min-height: 100vh;
  -webkit-animation: AnimationName 14s ease infinite;
  animation: AnimationName 14s ease infinite;
}

@-webkit-keyframes AnimationName {
  0% { background-position: 4% 0%; }
  50% { background-position: 97% 100%; }
  100% { background-position: 4% 0%; }
}

@keyframes AnimationName {
  0% { background-position: 4% 0%; }
  50% { background-position: 97% 100%; }
  100% { background-position: 4% 0%; }
}

#home2 {
  width: 100%;
}
#home3 {
  color: #00a9bb;
  font-family: "Fira Sans", sans-serif;
  font-weight: 700;
  font-size: clamp(2rem, 6vw, 3.5rem);
}
#home4 {
  color: #146ef5;
  font-family: "Fira Sans", sans-serif;
  font-weight: 700;
  font-size: clamp(2rem, 6vw, 3.5rem);
}
#home5 {
  margin-top: 4rem;
}
#home6 {
  font-family: "Fira Sans", sans-serif;
  font-size: clamp(0.8rem, 4vw, 1.1rem);
  font-weight: 300;
  color: #3d4449;
  margin-top: 1rem;
}
#home7 {
  margin-top: 1rem;
}
#home8 {
  border: 1px solid #c6c8cb;
  background-color: transparent !important;
  border-radius: 40px;
  padding: 8px 16px;
  color: #146ef5;
  font-family: "Fira Sans", sans-serif;
  font-weight: 400;
  font-size: 1rem;
}
#home9 {
  margin-top: 1rem;
  column-gap: 8px;
  display: flex;
  flex-direction: row;
}
#home10 {
  margin-top: 2rem;
}
#home11 {
  font-family: "Fira Sans", sans-serif;
  font-weight: 400;
  background-color: #146ef5;
  padding: 12px 24px;
  border: none;
  box-shadow: none;
  border-radius: 40px;
  font-size: 1rem;
  color: #fff;
}
#home12 {
  font-family: "Fira Sans", sans-serif;
  font-family: 300;
  color: #3d4449;
  font-size: 1.2rem;
  margin-top: 2rem;
}
#home13 {
  font-family: "Fira Sans", sans-serif;
  font-weight: 500;
  font-size: clamp(2rem, 4vw, 2.2rem);
  color: #00a9bb;
}
#home14 {
  font-family: "Fira Sans", sans-serif;
  font-weight: 500;
  font-size: clamp(2rem, 4vw, 2.2rem);
  color: #146ef5;
}
#home15 {
  margin-top: 2rem;
  font-family: "Fira Sans", sans-serif;
}
/* #home16{
  box-shadow: none;
  margin-top: 2rem;
  padding: 12px 24px;
  font-family: "Fira Sans", sans-serif;
  font-weight: 400;
  color: #fff;
  background-color: #146ef5;
  border-radius: 40px;
  width: 100%;
  margin-bottom: 2rem;
} */
@keyframes hover-animation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

#home16 {
  position: relative;
  margin-top: 2rem;
  overflow: hidden;
  width: 100%;
  border-radius: 40px;
  padding: 12px 24px;
  font-family: "Fira Sans", sans-serif;
  font-weight: 400;
  color: #fff;
  background: #146ef5;
  margin-bottom: 2rem;
}

#home16::before,
#home16::after {
  content: '';
  position: absolute;
  background: rgba(73, 245, 20, 0.2); /* Use the provided color with transparency */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  border-radius: 50%;
  transition: transform 0.3s cubic-bezier(0.2, 0.6, 0.8, 1);
}

#home16::before {
  width: 200%;
  height: 200%;
}

#home16::after {
  width: 150%;
  height: 150%;
}

#home16:hover::before,
#home16:hover::after {
  transform: translate(-50%, -50%) scale(1);
}

#home16:hover::before {
  transition-delay: 0.1s;
}

#home16:hover::after {
  transition-delay: 0.2s;
}

#home17{
  display: flex;

  align-items: center;
}
#home18{
  background-color: #146EF5;
  min-height: 100vh;
}
#home19{
  width: 100%;
}
#home20{
  padding-top: 3rem;
  padding-bottom: 1rem;
}
.inputfieldhome {
  font-family: "Fira Sans", sans-serif;
  color: red !important;
}

#home21{
  background-color: #AFE0F9;
  border-radius: 30px 0px 0px 30px;
  min-height: 40vh;
  padding: 2rem;
  display: flex;
  align-items: center;
}
#home22{
  margin-top: 2rem;
  margin-bottom: 2rem;
}
#home23{
  width: 100%;
}
#home24{
  background-color: #AFE0F9;
  border-radius: 0px 30px 30px 0px;
  
  padding: 2rem;
}
#home25{
  color: #146EF5;
  font-family: "Fira Sans", sans-serif;
  font-weight: 600;
  font-size: 2.6rem;

}
#home26{
  margin-top: 5rem;
  margin-bottom: 8rem;
}
#home27{
  padding: 2rem;
}
#home28{
  color: #146EF5;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
#home29{
  color: #146EF5;
  font-family: "Fira Sans", sans-serif;
  font-weight: 500;
  font-size: 2.4rem;
}
.features-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-family: "Fira Sans", sans-serif;
}

.features-list li {
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 2.6;
  position: relative;
  padding-left: 25px;
  color: #3D4449;
}

.features-list li:before {
  content: '';
  position: absolute;
  left: 0;
  top: 14px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ffffff;
  border: 2px solid #00A9BB;
}
#home30{
  margin-top: 2rem;
}
#home31{
  display: flex;
  align-items: center;
  justify-content: center;
}
#home32{
  font-family: "Fira Sans", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: #3D4449;
}

.fade-in-bottom {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

[class*="ref-"] {
  opacity: 0;
  transform: translateY(50px);
}

@media only screen and (max-width: 600px) {
  #home8 {
    border: 1px solid #c6c8cb;
    background-color: transparent !important;
    border-radius: 40px;
    padding: 8px 16px;
    color: #146ef5;
    font-family: "Fira Sans", sans-serif;
    font-weight: 400;
    font-size: 0.8rem;
  }
  #home9 {
    margin-top: 1rem;
    column-gap: 8px;
    display: flex;
    flex-direction: row;
  }
  #home18{
    background-color: #146EF5;
    min-height: 35vh;
  }
}
