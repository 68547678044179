.footer {
  background-color: #f8f9fa;
  padding: 40px 0 20px;
  color: #333;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-section {
  flex: 1;
  margin-bottom: 20px;
  min-width: 300px;
}

.footer-logo {
  max-width: 150px;
  margin-bottom: 15px;
}

.footer-section h3 {
  margin-bottom: 15px;
  font-size: 18px;
}

.footer-section p {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 10px;
  font-size: 18px;
}

.social-icons {
  display: flex;
  gap: 15px;
  margin-top: 15px;
}

.social-icons a {
  color: #333;
  font-size: 24px;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #007bff;
}

.footer-bottom {
  border-top: 1px solid #ddd;
  margin-top: 20px;
  padding-top: 20px;
  text-align: center;
  font-size: 14px;
}

.footer-bottom a {
  color: #333;
  text-decoration: none;
}

.footer-bottom a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
  }

  .footer-section {
    width: 100%;
  }
}

.email-link {
  color: inherit;
  text-decoration: none;
  transition: color 0.3s ease;
}

.email-link:hover {
  color: #007bff;
  /* or any color that fits your design */
  text-decoration: underline;
}